.contact {
  background-image: url("../../../public/images/img-home-1.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(1, 0, 7, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;

  height: 90vh;
}

.contact__title {
  color: #fff;
  font-size: 60px;
  flex-direction: column;
  display: flex;
  margin-bottom: 35px;
}

.contact__container {
  /* background-color: #242424; */
  /* padding: 4rem 0 2rem 0; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  /* align-items: center; */
  /* width: 650px; */
  width: 55%;
}

.contact__form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
}

.contact__label {
  color: #fff;
  margin-top: 1.3rem;
  margin-bottom: 0.2rem;
}

.contact__input {
  width: 100%;
  height: 35px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;

  &:focus {
    border: 2px solid rgba(0, 206, 158, 1);
  }
}

.contact__textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 150px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  resize: none;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;

  &:focus {
    border: 2px solid rgba(0, 206, 158, 1);
  }
}

.contact__input[type="submit"] {
  margin-top: 2rem;
  cursor: pointer;
  background: rgb(249, 105, 14);
  color: white;
  border: none;
  font-family: "PT Sans", sans-serif;
  font-size: 1rem;
  width: 130px;
  align-self: center;
}

@media screen and (max-width: 960px) {
  .contact__container {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .contact__container {
    width: 95%;
  }
}
