.services {
  /* background-image: url("../../../public/images/img-home-3.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(1, 0, 7, 0.5); */

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 10px 0px;
  /* padding: 2rem; */

  /* height: 100vh; */

  /* background: #fff; */
}

/* .services__title {
  color: #fff;
  font-size: 70px;
  flex-direction: column;
  display: flex;
} */

.services__container {
  /* background-color: #242424; */
  /* padding: 4rem 0 2rem 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* width: 650px; */
  width: 85%;
  margin-bottom: 100px;
}

.services__livecaption {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
}

.livecaption__title {
  align-self: start;
  font-size: 65px;
  margin-bottom: 20px;
  margin-left: 30px;
}

.services__pic-wrap {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 67%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.services__item__img-left {
  /* position: absolute; */
  /* top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  margin-top: 50px;
  margin-left: 0px;
  margin-right: 50px;
  display: block;
  width: 700px;
  /* width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%; */
  /* padding: 50px; */
  object-fit: cover;
  transition: all 0.2s linear;
  /* align-self: center; */
  border-radius: 25px;
  box-shadow: 20px 50px 20px rgba(5, 11, 21, 0.2);
  -webkit-filter: drop-shadow(20px 50px 20px rgba(5, 11, 21, 0.2));
  filter: drop-shadow(20px 50px 20px rgba(5, 11, 21, 0.2));
}

.services__item__img-right {
  display: block;
  width: 700px;
  object-fit: cover;
  transition: all 0.2s linear;
  align-self: end;
  border-radius: 25px;
  margin-left: 50px;
  box-shadow: 20px 50px 20px rgba(5, 11, 21, 0.2);
  -webkit-filter: drop-shadow(20px 50px 20px rgba(5, 11, 21, 0.2));
  filter: drop-shadow(20px 50px 20px rgba(5, 11, 21, 0.2));
}

.services__item__img-center {
  display: block;
  width: 750px;
  object-fit: cover;
  transition: all 0.2s linear;
  align-self: center;
}

.services__wrapper,
.services__wrapper-rev {
  display: flex;
  margin-bottom: 100px;
}

.services__description {
  display: flex;
  /* margin-top: 150px; */
  justify-content: center;
  align-items: center; /* Add this line */
  /* vertical-align: middle; */
  font-size: 36px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

@media screen and (max-width: 960px) {
  .services__container {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .services__container {
    width: 100%;
    flex-direction: column;
    align-items: center; /* Center the items horizontally */
  }

  .services__wrapper {
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
  }

  .services__wrapper-rev {
    display: flex;
    margin-bottom: 30px;
    flex-direction: column-reverse;
  }

  .services__item__img-left,
  .services__item__img-right {
    align-self: center;
    margin-top: 50px;
    margin-left: 0;
    margin-right: 0;
    display: block;
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
    margin-bottom: 40px;
  }

  .services__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 24px;
    text-align: center;
  }
}
