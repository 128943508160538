.works {
  color: #000000;
  /* background-image: url("../../../public/images/img-works-2.jpeg"); */
  /* box-shadow: inset 0 0 0 1000px rgba(1, 0, 7, 0.5); */
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem;
  background: #ffffff;
}

/* .works {
  background-image: url("../../../public/images/img-3.jpg");
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 100px;
} */

h1 {
  text-align: center;
  /* font-size: 100px; */
}

.works__title {
  font-size: 65px;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
  /* color: #fff; */
  color: #000000;
  letter-spacing: 0.1rem;
  text-align: start;
}

.works__container {
  color: black;
  display: flex;
  flex-flow: column;
  /* align-items: center; */
  /* max-width: 1120px; */
  width: 85%;
  margin: 0 auto;
}

.works__wrapper {
  position: relative;
  margin: 30px 0 45px;
}

.works__items {
  margin-bottom: 24px;
}

.works__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.works__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.works__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.works__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.works__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.works__item__img:hover {
  transform: scale(1.1);
}

.works__item__info {
  padding: 15px 25px 30px;
  background: #ffffff;
}

.works__item__title {
  color: #252e48;
  /* color: #ffffff; */
  font-size: 20px;
  line-height: 24px;
}

.works__item__subtitle {
  color: #252e48;
  /* color: #ffffff; */
  font-size: 14px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .works__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .works__item {
    margin-bottom: 2rem;
  }
}
