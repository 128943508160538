* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home
/* .services, */
/* .contact, */
/* .works, */
/* .about */ {
  display: flex;
  height: 90vh;
  align-items: start;
  justify-content: center;
  font-size: 3rem;
}

/* .services {
  background-image: url("../public/images/img-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

/* .contact {
  background-image: url("../public/images/img-1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

/* .works {
  background-image: url("../public/images/img-3.jpg");
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 100px;
} */

/* .about {
  background-image: url("../public/images/img-8.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */
