.about {
  color: #000000;
  background-image: url("../../../public/images/about_background-crop.png");
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.3);
  justify-content: flex-start;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem;
  display: flex;
  /* background: #ffffff; */
}

.about__container {
  margin-left: 120px;
  margin-bottom: 200px;
  color: black;
  /* display: flex; */
  /* flex-flow: column; */
  /* align-items: center; */
  /* max-width: 1120px; */
  width: 40%;
  /* margin: 0 auto; */
}

.about__title {
  font-size: 65px;
  /* margin-left: 15px; */
  margin-top: 15px;
  margin-bottom: 50px;
  /* color: #fff; */
  color: #000000;
  letter-spacing: 0.1rem;
  text-align: start;
}

.about__content {
  color: #fff;
  font-size: 28px;
  font-weight: 100;
  /* font-family: Arial, Helvetica, sans-serif; */
}

.about__paragraph-eng {
  text-indent: 0.4em;
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 2.5rem;
  /* color: #fff; */
  color: #000000;
  font-size: 24px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.about__paragraph-kor {
  margin-top: 50px;
  margin-bottom: 50px;
  /* color: #fff; */
  color: #000000;
  font-size: 24px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .about__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .about__item {
    margin-bottom: 2rem;
  }

  .about {
    justify-content: center;
  }
  .about__container {
    margin-left: 0px;
    color: black;
    /* display: flex; */
    /* flex-flow: column; */
    /* align-items: center; */
    /* max-width: 1120px; */
    width: 90%;
  }
}
